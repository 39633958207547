
$(document).on("click", ".btn-apply", function(e) {
    e.preventDefault();

    let category = $("[name=category_slug]").val();
    let timber = [];
    let subcategory = [];
    let page = 1;

    $("input[name='timber[]']:checked").each(function() {
        timber.push($(this).val());
    });

    $("input[name='subcategory[]']:checked").each(function() {
        subcategory.push($(this).val());
    });
    
    var data = {
        category: category,
        timber: timber,
        subcategory: subcategory,
        page: page,
        paginate: (window.innerWidth < 768) ? 10 : 9
    };
    
    ajaxItemProduct(data);
});

$(window).on("load", function() {
    if ($(".all-products")[0]) {
        var productClick = $(".all-products label").trigger("click");
        if (productClick.length) {
            $(".btn-apply").trigger("click");
        }
    } else {
        $(".btn-apply").prop("disabled", true);
    }

    $(document).on("click", 'input[name="products"]', function(e) {
     if($('input[name="products"]:checked').length > 0) {
            $('input[name="subcategory[]"]').prop('checked', false);
        }
    });
    
    $(document).on("click", 'input[name="subcategory[]"]', function(e) {
     if($('input[name="subcategory[]"]:checked').length > 0) {
            $('input[name="products"]').prop('checked', false);
        }
    });
    $(document).on("click", ".item-product", function(e) {
        if (
            $('input[name="timber[]"]:checked').length > 0 ||
            $("input[name=products]:checked").length > 0 ||
            $('input[name="subcategory[]"]:checked').length > 0
        ) {
            $(".btn-apply").prop("disabled", false);
        } else {
            $(".btn-apply").prop("disabled", true);
        }

        // if($('input[name="subcategory[]"]:checked').length > 0) {
        //     $('input[name=products]').prop('checked', false);
        // }
   
    });
    $(document).on("click", ".item-timber", function(e) {
        if (
            $('input[name="timber[]"]:checked').length > 0 ||
            $("input[name=products]:checked").length > 0 ||
            $('input[name="subcategory[]"]:checked').length > 0
        ) {
            $(".btn-apply").prop("disabled", false);
        } else {
            $(".btn-apply").prop("disabled", true);
        }
    });
});

$(".wrap-list-product-category").on("click", ".page-link:not(.disabled)", function(e) {
    e.preventDefault();
    var page = $(this)
        .attr("href")
        .split("page=")[1];
    let category = $("[name=category_slug]").val();
    let timber = [];
    let subcategory = [];
    $("input[name='timber[]']:checked").each(function() {
        timber.push($(this).val());
    });
    $("input[name='subcategory[]']:checked").each(function() {
        subcategory.push($(this).val());
    });

    var data = {
        category: category,
        timber: timber,
        subcategory: subcategory,
        page: page,
        paginate: (window.innerWidth < 768) ? 10 : 9
    };
    ajaxItemProduct(data);
});

function ajaxItemProduct(params) {
    $.ajax({
        type: "POST",
        url: BASE_URL+"/search/ajax",
        data: params,
        beforeSend: function() {
            $(".preloader").fadeIn();
        },
        success: function(data) {
            if (data.status == "success") {
                let pos = $("#list_products");
                let pagi = $("#pagination");
                let text = {
                    'see_product'   :  data.see_product,
                    'add_wishlist'  : data.text_add_wishlist,
                    'remove_wishlist'   : data.text_remove_wishlist,
                };
                let Products = data.result.products;
                pos.empty();
                if (
                    typeof Products.data != "undefined" &&
                    Products.data.length > 0
                ) {

                    let item = new Array();
                    Products.data.forEach(el => {
                        item.push(item_product_el(el, text));
                    });

                    $('<div class="row no-gutters">')
                        .append(item.join(""))
                        .appendTo("#list_products");
                    pagi.empty();
                    pagi.append(pagination(Products));
                    $('[data-toggle="tooltip"]').tooltip();

                } else {
                    pos.append(data.product_not_found);
                }
            }
        },
        complete: function() {
            $(".preloader").fadeOut();
        },
        error: function(jqXHR) {
            console.log(jqXHR);
        }
    });
}
function item_product_el(data, text) {
    try {
        var wishlist = data.wishlist? 'checked' : '';
        var titleWishlist = data.wishlist? text.remove_wishlist : text.add_wishlist;
        var price = formatMoney(data.global_price[0].product_global_price);
        var tagPhoto =
            typeof data.group_tags.Timber !== "undefined"
                ? data.group_tags.Timber[0].tag_photo
                : "";
        var tagName =
            typeof data.group_tags.Timber !== "undefined"
                ? data.group_tags.Timber[0].tag_name
                : "";
        var collectionName =
            typeof data.group_tags.Collection !== "undefined"
                ? data.group_tags.Collection[0].tag_name
                : "";
        var itemProduct =
            '<div class="col-6 col-md-4">\
              <div class="item">\
                 <a title="' +
                 data.product_name +'" href="/product/' +
            data.product_slug +
            '">\
                      <div class="product-category">\
                          <img class="product-category-image" src="' +
            tagPhoto +
            '">\
                          <div class="title">' +
            tagName +
            '</div>\
                      </div>\
                      <div class="box-image">\
                          <img class="lazyload img-fluid" width="250" height="250" data-src="' +
            data.photos[0].url_product_photo +
            '">\
            <div class="wrap-button">\
                <button class="btn btn-primary">'+text.see_product+'</button>\
            </div>\
            <div class="add-wishlist">\
                <label data-toggle="tooltip" data-placement="top" data-original-title="'+titleWishlist+'" >\
                    <input type="checkbox" name="wishlist" class="checkboxnya" data-id="'+data.product_code+'" '+wishlist+'>\
                    <span for="wishlist" class="checker"> </span>\
                </label>\
            </div>\
                      </div>\
                      <div class="meta">\
                          <h4 class="category">' +
            collectionName +
            '</h4>\
                          <h2 class="title">' +
            data.product_name +
            '</h2>\
                          <div class="price">IDR ' +
            price +
            "</div>\
                      </div>\
                  </a>\
              </div>\
          </div>";
        return itemProduct;
    } catch (error) {
        console.log(error);
    }
}

$( function () {
    if($(".filter-timber .item-timber").length > 7) {
        $(window).on("resize", listenWidth);
        function listenWidth( e ) {
            var width = $(window).width();
            if (width > 991){
                $(".filter-timber .item-timber").height('190px');
                $(".filter-timber").height('500px');
            } else {
                $(".filter-timber .item-timber").removeAttr("style");
                $(".filter-timber").removeAttr("style");
            }
        };
        listenWidth();
    }

});
