const { isEmpty } = require("lodash");

$(function(){
    if($('.search-result').length > 0) {
        var timber = $('#list_products').data('tags');
        var collection = $('#list_products').data('collection');
        var subcategory = $('#list_products').data('subcategory');
        var page = $('#list_products').data('page');
        var bestSeller = $('#list_products').data('best-seller');
        let paginate = (window.innerWidth < 768) ? 10 : 9;

        var data = {
            search: $('#list_products').data('keyword'),
            timber: isEmpty(timber) != true ? timber.split(',') : [],
            collection: isEmpty(collection) != true ? collection.split(',') : [],
            subcategory: isEmpty(subcategory) != true ? subcategory.split(',') : [],
            page: page??1,
            best_seller : bestSeller == "1" ? 1 : 0,
            paginate: paginate
        };
        ajaxItemProduct(data);
    }

    $(document).on("keyup", ".search-triger-input", function(e) {
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set('keyword', this.value)
        queryParams.toString();
        history.replaceState(null, null, "?"+queryParams.toString());
        btnSearch();
    });
    $(document).on("change", ".search-triger-input", function(e) {
        btnSearch();
    });

    function btnSearch() {

        if(  ($("[name='keyword']").val() != '' || $(".material-modal input[name='timber[]']:checked").length > 0 ||  $(".collection-modal [name='collection[]']").val() != '' && $(".collection-modal [name='collection[]']").val() != null || $(".collection-modal [name='subcategory[]']").val() != '' &&  $(".collection-modal [name='subcategory[]']").val() != null )  || $(".material-modal [name='best_seller']:checked").val() != '' && $(".material-modal [name='best_seller']:checked").val() != null ) {
            $(".btn-search").prop("disabled", false);
        } else {
            $(".btn-search").prop("disabled", true);
        }
    }

    $(document).on("click", ".btn-search", function(e) {
        e.preventDefault();
    
        let page = 1;
        let timber = [];
        let collection = [];
        let subcategory = [];
        let search = $("[name='keyword']").val();
        let bestseller = $(".material-modal input[name='best_seller']:checked").val() == 1 ? 'best_seller=1&' : '';

        $(".material-modal input[name='timber[]']:checked").each(function() {
            if( ($(this).val()) !== "" ) {
                timber.push($(this).val());
            }
        });
    
        $(".collection-modal [name='subcategory[]']").each(function() {
            subcategory.push($(this).val());
        });

        $(".collection-modal [name='collection[]']").each(function() {
            if( ($(this).val()) !== "" ) {
                collection.push($(this).val());
            }
        });
        
        
        window.location = BASE_URL+'/search?'+bestseller+'keyword=' + search + "&timber=" + timber.join(',') + "&collection=" + collection.join(',') + "&subcategory=" + subcategory.join(',');

    });
    // $('.search-result').on("click", ".page-link:not(.disabled)", function(e) {
    //     e.preventDefault();
    //     var page = $(this)
    //         .attr("href")
    //         .split("page=")[1];

    //     let timber = [];

    //     let subcategory = [];

    //     $(".material-modal input[name='timber[]']:checked").each(function() {
    //         timber.push($(this).val());
    //     });

    //     $(".collection-modal [name='subcategory[]']").each(function() {
    //         subcategory.push($(this).val());
    //     });

    //     let search = isEmpty($('#list_products').data('keyword')) == true? '' : $('#list_products').data('keyword');

    //     window.location = 'search?keyword=' + search + "&timber=" + timber.join(',') + "&subcategory=" + subcategory.join(',')+"&page="+page;
    // });

    function ajaxItemProduct(params) {
        $.ajax({
            type: "POST",
            url: BASE_URL+"/search/ajax",
            data: params,
            beforeSend: function() {
                $(".preloader").fadeIn();
            },
            success: function(data) {
                let pos = $("#list_products");
                if (data.status == "success") {
                    let pagi = $("#pagination");
                    let text = {
                        'see_product'   :  data.see_product,
                        'add_wishlist'  : data.text_add_wishlist,
                        'remove_wishlist'   : data.text_remove_wishlist,
                    };

                    let Products = data.result;

                    pos.empty();
                    if( typeof data.result.search_query.subcategory != "undefined") {
                        if(data.result.search_query.subcategory != null) {
                            $('#search_result_subcategory').html(', "'+data.result.search_query.subcategory+'"')
                        } else {
                            $('#search_result_subcategory').html(', "'+data.allproduct+'"')
                        }
                    }

                    if ( typeof Products.data != "undefined" && Products.data.length > 0 ) {
                        let item = new Array();

                        Products.data.forEach(el => {
                            item.push(item_product_el(el, text));
                        });

                        $('<div class="row no-gutters">')
                            .append(item.join(""))
                            .appendTo("#list_products");

                        pagi.empty();

                        pagi.append(pagination(Products));
                        $('[data-toggle="tooltip"]').tooltip();

                    } else {
                        pos.append("<div class=product-not-found>"+data.product_not_found+"</div>");
                    }
                } else {
                    pos.append("<div class=product-not-found>"+data.fail+"</div>");
                }
            },
            complete: function() {
                $(".preloader").fadeOut();
            },
            error: function(jqXHR) {
                console.log(jqXHR);
            }
        });
    }
    function item_product_el(data, text) {
        try {
            var wishlist = data.wishlist? 'checked' : '';
            var titleWishlist = data.wishlist? text.remove_wishlist : text.add_wishlist;
            var price = formatMoney(data.global_price[0].product_global_price);
            var photo_url = typeof data.photos[0] != 'undefined' ? data.photos[0].url_product_photo : BASE_URL+'/img/default.png';
            var tagPhoto =
                typeof data.group_tags.Timber !== "undefined"
                    ? data.group_tags.Timber[0].tag_photo
                    : "";
            var tagName =
                typeof data.group_tags.Timber !== "undefined"
                    ? data.group_tags.Timber[0].tag_name
                    : "";
            var collectionName =
                typeof data.group_tags.Collection !== "undefined"
                    ? data.group_tags.Collection[0].tag_name
                    : "";
            var itemProduct =
                '<div class="col-6 col-md-4">\
                  <div class="item">\
                     <a title="' +
                     data.product_name +'" href="/product/' +
                data.product_slug +
                '">\
                          <div class="product-category">\
                              <img class="lazyload product-category-image" data-src="' +
                tagPhoto +
                '">\
                              <div class="title">' +
                tagName +
                '</div>\
                          </div>\
                          <div class="box-image">\
                              <img class="lazyload img-fluid" width="250" height="250" data-src="' +
                              photo_url +
                '">\
                <div class="wrap-button">\
                <button class="btn btn-primary">'+text.see_product+'</button>\
            </div>\
            <div class="add-wishlist">\
                <label data-toggle="tooltip" data-placement="top" data-original-title="'+titleWishlist+'" >\
                    <input type="checkbox" name="wishlist" class="checkboxnya" data-id="'+data.product_code+'" '+wishlist+'>\
                    <span for="wishlist" class="checker"> </span>\
                </label>\
            </div>\
                          </div>\
                          <div class="meta">\
                              <h4 class="category">' +
                collectionName +
                '</h4>\
                              <h2 class="title">' +
                data.product_name +
                '</h2>\
                              <div class="price">IDR ' +
                price +
                "</div>\
                          </div>\
                      </a>\
                  </div>\
              </div>";
            return itemProduct;
        } catch (error) {
            console.log(error);
        }
    }

    $(document).on('click', '#nav_modal_search', function(e){
        e.preventDefault();
        categorylist();
    });
    function categorylist() {
        const modalSeach = $('[name="category-modal-search"]');
        $.ajax({
            type: "POST",
            url: "/category/list",
            beforeSend: function() {
                // $(".preloader").fadeIn();
            },
            success: function(data) {
                $('#modal-list-timber').empty();
                data.timber.forEach(u => {
                    $('#modal-list-timber').append(timber_el(u));
                });

                modalSeach.empty();
                $(".collection-modal [name='subcategory[]']").select2({
                    placeholder: {
                        id: '-1', // the value of the option
                        text: 'All Product',
                      },
                    allowClear: false,
                    data: data.subcategory
                  })

                $(".collection-modal [name='collection[]']").select2({
                    placeholder: {
                        id: '-1', // the value of the option
                        text: 'All Collection',
                      },
                    allowClear: false,
                    data: data.collection
                  })
            },
            complete: function() {
                
                // $(".preloader").fadeOut();
            },
            error: function(jqXHR) {
                console.log(jqXHR);
            }
        });
    }

    function timber_el(u) {
        return '<div class="item-timber">'+
                    '<label class="" for="'+u.name+'">'+
                        '<input type="checkbox" class="checkboxnya search-triger-input" id="'+u.name+'" value="'+u.name+'" name="timber[]">'+
                        '<span for="timber[]" class="checker"> </span>'+
                        ''+u.name+''+
                        '<img class="custom-control-label-image" for="'+u.name+'" src="'+u.tag_photo+'" />'+
                    '</label>'+
                '</div>';
    }

});
