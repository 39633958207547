if ($("#image_zoom").length > 0) {
    $(function() {
        
        let lebar = $('.box-image').width();
        var options = {
            width: lebar,
            zoomWidth: 400,
            offset: {vertical: 0, horizontal: 10}
        };
        new ImageZoom(document.getElementById("image_zoom"), options);
        
    });
}
