const { ajax, param } = require("jquery");

$(document).on('click', '.btn_add_to_cart', function(e){
    e.preventDefault();
    let amount = $("input[name=amount]").val();
    let product_code = $("input[name=product_code]").val();
    let quantity = $("input[name=quantity]").val();
    let page = 'modal';

    $("input[name='timber[]']:checked").each(function(){
        timber.push($(this).val());
    });
    $("input[name='subcategory[]']:checked").each(function(){
        subcategory.push($(this).val());
    });
    var data = {
        amount:amount,
        product_code:product_code,
        quantity:quantity,
    }
    addToCart(data, page)
});

$(document).on('click', '#nav_modal_cart', function(e){
    e.preventDefault();
    checkCart('modal');
});

$(document).on('click', '.delete-link', function(e){
    e.preventDefault();
    var data = {
        product_code : $(this).closest(".item")[0].id,
    }
    var page = $(this).data('page');
    return deleteCart(data, page);
});

if (document.getElementsByClassName('list_shopping_cart')) {
    checkCart();
}

function addToCart(params, page) {
    $.ajax({
       type:'POST',
       url:BASE_URL+"/add-to-cart",
       data:params,
        beforeSend:function() {
            $(".preloader").fadeIn();
        },
        success:function(data){
            $('#modal_cart').modal('show');
            if(data.status == 'success'){
                let modal = $("#list_modal_cart");
                let carts = data.data;
                modal.empty();
                if(Object.keys(carts).length > 0) {
                    $('.shop-menu').find('.count').css('display','block');
                    $('.shop-menu').find('.count').html(Object.keys(carts).length);

                    let item = new Array();
                    Object.entries(carts).forEach(([key, product]) => {
                        item.push(item_cart_el(product, page));
                    });
                    $('<div class="wrap-item">').append(item.join('')).appendTo('#list_modal_cart');
                    $('.modal-footer').addClass('d-block');
                    sumTotalAmount(data);
                    
                } else {
                    modal.append(data.message);
                    $('.modal-footer').removeClass('d-block');
                    $('#btn_go_checkout').prop('disabled', true)

                }
            }
        },
        complete:function(){
            $(".preloader").fadeOut();
        },
        error:function(jqXHR){
            console.log(jqXHR)
        }
    });
}

function checkCart(page, params) {
    $.ajax({
        type:'POST',
        url:BASE_URL+"/list-cart",
        data:params,
         beforeSend:function() {
             $(".preloader").fadeIn();
         },
         success:function(data){
             if(data.status == 'success'){
                const modal = $("#list_modal_cart");
                const cartPage = $(".list_shopping_cart");
                const carts = data.data;
                // let grandtotal = (typeof data.grandtotal != 'undefined') ? data.grandtotal : '';
                let point_will_receive = (typeof data.point_will_receive != 'undefined') ? data.point_will_receive : '';
                if(page == 'modal'){
                    $('#modal_cart').modal('show');
                    modal.empty();
                } else {
                    cartPage.empty();
                    // if(grandtotal != '') {
                    //     $('.modal_total_amount').html(grandtotal);
                    // }
                    if($("#poin_number").length > 0) {
                        $("#poin_number").html(formatMoney(point_will_receive));
                    }
                }
                if(Object.keys(carts).length > 0) {
                    $('.shop-menu').find('.count').css('display','block');
                    $('.shop-menu').find('.count').html(Object.keys(carts).length);
                    let datacart = {
                        data : carts
                    }
                    sumTotalAmount(datacart);
                    let item = new Array();
                    Object.entries(carts).forEach(([key, product]) => {
                        item.push(item_cart_el(product, page));
                    });
                    if(page == 'modal'){
                        $('<div class="wrap-item">').append(item.join('')).appendTo('#list_modal_cart');
                        $('.modal-footer').addClass('d-block');
                    } else {
                        $('#btn_go_checkout').prop('disabled', false)

                        $('<div class="wrap-item">').append(item.join('')).appendTo('.list_shopping_cart');
                    }
                } else {
                    if(page == 'modal'){
                        modal.append(data.message);
                        $('.modal-footer').removeClass('d-block');
                    } else {
                        cartPage.append(data.message);
                        $('#btn_go_checkout').prop('disabled', true)

                    }
                }
             }
         },
         complete:function(){
             $(".preloader").fadeOut();
         },
         error:function(jqXHR){
             console.log(jqXHR)
         }
     });
}

function item_cart_el(product, pos) {
    var timberPhoto = (typeof product.timber.tag_photo != 'undefined') ? product.timber.tag_photo : '';
    var timberName = (typeof product.timber.tag_name != 'undefined') ? product.timber.tag_name : '';
    var collectionName = (typeof product.collection != 'undefined') ? product.collection.tag_name : '';
    var leatherName = (product.leather != null && typeof product.leather.name != 'undefined') ? product.leather.name : '';
    var leatherPhoto = (product.leather != null && typeof product.leather.image_url != 'undefined') ? product.leather.image_url : '';
    var price = (typeof product.amount != 'undefined') ? formatMoney(product.amount) : formatMoney(product.product_subtotal_price);
    var priceNum = (typeof product.amount != 'undefined') ? product.amount : product.product_subtotal_price;
    var basePrice = (typeof product.global_price != 'undefined') ? product.global_price : product.global_price[0].product_global_price;
    var basePriceFormat = (typeof product.global_price != 'undefined') ?formatMoney(product.global_price) : formatMoney(product.global_price[0].product_global_price);
    var thumb = (typeof product.photos != 'undefined' ) ? product.photos : product.photos[0].url_product_photo;

    var modal = '<div id="'+product.product_code+'" class="item">'+
        '<div class="row no-gutters">'+
            '<div class="col-auto">'+
                '<div class="thumb">'+
                    '<img class="lazyload img-fluid" data-src="'+thumb+'" />'+
                '</div>'+
            '</div>'+
            '<div class="col-6">'+
                '<div class="meta-description">'+
                    '<div class="title">'+collectionName+'</div>'+
                    '<a href="'+BASE_URL+'/product/'+product.product_slug+'" class="subtitle">'+product.product_name+'</a>'+
                    '<span class="category">'+
                        '<span class="wrap-image-category">'+
                        '<img class="lazyload img-fluid" data-src="'+ timberPhoto +'" alt="'+ timberName +'" title="'+ timberName +'" />'+
                        '</span>'+
                        '<span class="wrap-image-category">'+
                        '<img class="lazyload img-fluid" data-src="'+ leatherPhoto +'"  alt="'+ leatherName +'" title="'+ leatherName +'"/>'+
                        '</span>'+
                    '</span>'+
                    '<div class="price"><span class="currency">IDR </span><span class="price_qty" data-id="'+priceNum+'">'+price+'</span></div>'+
                '</div>'+
           '</div>'+
            '<div class="col align-self-center">'+
            '<div class="product-quantity">'+
                '<input type="button" id="minus" class="minus" value="-">'+
                '<input type="number" class="input-text qty text" step="1" min="1" max="" id="qty_id" name="cart['+product.product_code+'][quantity]" value="'+product.quantity+'" title="Qty" size="4" pattern="[0-9]*" inputmode="numeric" >'+
                '<input type="button" id="plus" class="plus" value="+">'+
                '<input type="hidden" name="old_price_id" value="'+basePrice+'">'+
            '</div>'+
            '<div class="wrap-delete-link">'+
                '<div class="delete-link" data-page="modal">'+
                    '<a href="#" id="delete_'+product.product_code+'" data-id="'+product.product_code+'" class="btn-delete"><img class="lazyload img-fluid" width="22px" src="/img/icon/icon-trash.png" /></a>'+
             '</div>'+
            '</div>'+
        '</div>'+
        '</div>'+
    '</div>';
    var page = '<div id="'+product.product_code+'" class="item">'+
        '<div class="row no-gutters">'+
            '<div class="col-3 col-md-2 col-lg-2 order-1 order-md-1 order-lg-1 d-flex justify-content-center align-items-center align-self-center">'+
                '<div class="thumb">'+
                    '<img class="lazyload img-fluid" data-src="'+thumb+'" />'+
                '</div>'+
            '</div>'+
            '<div class="col-8 col-md-4 col-lg-4 order-2 order-md-2 order-lg-2 d-flex justify-content-start align-items-center align-self-center">'+
                '<div class="meta-description">'+
                    '<div class="title">'+collectionName+'</div>'+
                    '<a href="'+BASE_URL+'/product/'+product.product_slug+'" class="subtitle">'+product.product_name+'</a>'+
                    '<div class="category">'+
                    '<span class="wrap-image-category">'+
                    '<img class="lazyload img-fluid" data-src="'+ timberPhoto +'" alt="'+ timberName +'" title="'+ timberName +'" />'+
                    '</span>'+
                    '<span class="wrap-image-category">'+
                    '<img class="lazyload img-fluid" data-src="'+ leatherPhoto +'"  alt="'+ leatherName +'" title="'+ leatherName +'"/>'+
                    '</span>'+
                    '</div>'+
                    '<div class="price"><span class="currency">IDR </span><span class="base_price" data-id="'+basePriceFormat+'">'+basePriceFormat+'</span></div>'+
                '</div>'+
            '</div>'+
            '<div class="col-4 col-md-2 col-lg-2 offset-4 offset-md-0 offset-lg-0 order-4 order-md-3 order-lg-3 d-flex justify-content-center align-items-center align-self-center">'+
                '<div class="product-quantity">'+
                    '<input type="button" id="minus" class="minus" value="-">'+
                    '<input type="number" class="input-text qty text" step="1" min="1" max="" id="qty_id" name="cart['+product.product_code+'][quantity]" value="'+product.quantity+'" title="Qty" size="4" pattern="[0-9]*" inputmode="numeric" >'+
                    '<input type="button" id="plus" class="plus" value="+">'+
                    '<input type="hidden" name="old_price_id" value="'+basePrice+'">'+
                '</div>'+
            '</div>'+
            '<div class="col-4 col-md-3 col-lg-3 order-5 order-md-4 order-lg-4 d-flex justify-content-end align-items-center align-self-center">'+
                '<div class="price"><span class="currency">IDR </span><span class="price_qty" data-id="'+priceNum+'">'+price+'</span></div>'+
            '</div>'+
            '<div class="col-1 col-md-1 col-lg-1 order-3 order-md-5 order-lg-5 d-flex justify-content-center align-items-center align-self-center">'+
                '<div class="wrap-delete-link">'+
                    '<div class="delete-link" data-page="page">'+
                        '<a href="#" id="delete_'+product.product_code+'" data-id="'+product.product_code+'" class="btn-delete"><img class="lazyload img-fluid" width="22px" src="/img/icon/icon-trash.png" /></i></a>'+
                    '</div>'+
                '</div>'+
            '</div>'+
        '</div>'+
    '</div>';
    if(pos == 'modal') {
        return modal;
    } else {
        return page;
    }
}

function deleteCart(params, page) {
    $.ajax({
        type:'POST',
        url: BASE_URL+"/delete-cart-text",
        data:{},
        beforeSend:function() {
            $(".preloader").fadeIn();
        },
         success:function(data){
            if(window.innerWidth < 768) {
                var reverseButtons = true
            } else {
                var reverseButtons = false
            }
            Swal.fire({
                title: data.title,
                text: data.text,
                showCloseButton: true,
                showDenyButton: true,
                confirmButtonText: data.btn_text,
                confirmButtonTextColor: '#000000',
                denyButtonText: '<span class="move-it">'+data.deny_btn_text+'</span>',
                denyButtonColor: '#000000',
                reverseButtons:reverseButtons
            }).then((result) => {
                if (result.isConfirmed) {
                    ajaxDelete(params, page);
                } else if(result.isDenied) {
                    ajaxWishlist(params);
                    ajaxDelete(params, page);
                }
            });
         },
         complete:function(){
            $(".preloader").fadeOut();
        },
         error:function(jqXHR){
             console.log(jqXHR)
         }
     });
}
function ajaxWishlist(params) {
    $.ajax({
        type:'POST',
        url: BASE_URL+"/wishlist/add-wishlist",
        data:params,
         success:function(data){
             if(data.status == 'failed') {
                window.location.replace(BASE_URL+"/login");
             }
             $('.notification-top').show();
             $('#notify-2').prop('checked', false);
             $("#notify2_message").html(data.message);
             if($('#notify-2').length  == 1) {
                 setTimeout(function () {
                     $('#notify-2').trigger('click');
                 }, 3000);
             }
         },
         complete:function(){},
         error:function(jqXHR){
             console.log(jqXHR)
         }
     });
}
function ajaxDelete(params, page) {
    $.ajax({
        type:'POST',
        url: BASE_URL+"/delete-cart",
        data:params,
         beforeSend:function() {
             $(".preloader").fadeIn();
         },
         success:function(data){
             if(data.status == 'success'){
                 let modal = $("#list_modal_cart");
                 let cartPage = $(".list_shopping_cart");
                 let carts = data.data;
                 modal.empty();
                 cartPage.empty();
                 if(Object.keys(carts).length > 0) {
                    $('.shop-menu').find('.count').css('display','block');
                    $('.shop-menu').find('.count').html(Object.keys(carts).length);
                     let item = new Array();
                     Object.entries(carts).forEach(([key, product]) => {
                         item.push(item_cart_el(product, page));
                     });
                     $('<div class="wrap-item">').append(item.join('')).appendTo(modal);
                     $('<div class="wrap-item">').append(item.join('')).appendTo(cartPage);
                     $('.modal-footer').addClass('d-block');
                     $('#btn_go_checkout').prop('disabled', false);

                     //  if($('#poin_number').length > 0) {
                         $('#poin_number').html(formatMoney(data.point_will_receive));
                    //  }

                 } else {
                     modal.append(data.message);
                     cartPage.append(data.message);
                     $('.shop-menu').find('.count').css('display','none');
                     $('.modal-footer').removeClass('d-block');
                     $('#poin_number').html(0);
                     $('#btn_go_checkout').prop('disabled', true)

                 }
                 sumTotalAmount(data);
             }
         },
         complete:function(){
             $(".preloader").fadeOut();
         },
         error:function(jqXHR){
             console.log(jqXHR)
         }
     });
}