$(function () {
    if($('input[name="date_of_birth"]').length > 0) {

        $('input[name="date_of_birth"]').daterangepicker({
            autoUpdateInput: true,
            singleDatePicker: true,
            showDropdowns: true,
            minYear: 1901,
            maxYear: parseInt(moment().format('YYYY'), 10),
            locale: {
                format: 'DD-MM-YYYY'
                }
        }, function (start, end, label) {
            var years = moment().diff(start, 'years');
        });
        $('input[name="date_of_birth"]').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD-MM-YYYY'));
        });
      
        $('input[name="date_of_birth"]').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
        });
    }
    

    if($('input[type=tel]').length > 0) {
        $('input[type=tel]').on("keypress keyup blur",function (event) {    
            if($(this).val().length < 15) {
                $(this).val($(this).val().replace(/[^\d].+/, ""));
                if ((event.which < 48 || event.which > 57)) {
                    event.preventDefault();
                }
            } else{
                event.preventDefault();
            }
        });
    }
    
});
