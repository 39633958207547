var csrfToken = $('meta[name="csrf-token"]').attr('content');

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": csrfToken,
    }
});
$(function () {
    setInterval(refreshToken, 3600000); // 1 hour 
    function refreshToken(){
        $.get('refresh-csrf').done(function(data){
            csrfToken = data; // the new token
        });
    }

    setInterval(refreshToken, 3600000); // 1 hour 
});
window.previewFile = function (input){
    var file = $(input).get(0).files[0];
    var image = $(input).prop('files')[0];
    var trxid  = $(input).closest('.add-review').data('trx-id');
    var product_code  = $(input).closest('.add-review').data('product-code');
    
    if(file){
        var reader = new FileReader();

        reader.onload = function(){
            $(input).closest('.item-image').css({
                "background-image": "url('"+reader.result+"')",
                "background-size": "cover",
                "border" : "none",
                });
            $(input).closest('.item-image').find('i').css("display", "none");
        }

        reader.readAsDataURL(file);

        var formData = new FormData()
        formData.append('image', image)
        formData.append('trx_id', trxid)
        formData.append('product_code', product_code)

        if($(input).attr('data-image-id') != 'undefined') {
            $.ajax({
                url: BASE_URL+'/my-account/order/history/delete-image-review',
                type: 'post',
                data: {
                    id : $(input).attr('data-image-id'),
                    trx_id : trxid,
                    product_code : product_code,
                },
                beforeSend : function (params) {
                    // $(".preloader").fadeIn();
                },
                success: function(response){             
                    // Add response in Modal body
                },
                complete:function(){
                    // $(".preloader").fadeOut();
                },
                error:function(jqXHR){
                    console.log(jqXHR)
                }
                
            });
        }


        $.ajax({
            url: BASE_URL+'/my-account/order/history/upload-image-review',
            type: 'post',
            data: formData,
            processData: false,
            cache: false,
            contentType: false,
            beforeSend : function (params) {
                $(".preloader").fadeIn();
            },
            success: function(response){             
                // Add response in Modal body
                $(input).closest('.add-review').data({ imageId: response.list });
                $(input).attr('data-image-id', response.single);
            },
            complete:function(){
                $(".preloader").fadeOut();
            },
            error:function(jqXHR){
                console.log(jqXHR)
            }
            
        });
    }
}

window.formatMoney = function (number) {
    var number = parseInt(number);
    var num = Math.round(number * 10000) / 10000;
    return num.toLocaleString('id-ID', { 
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}
window.sumTotalAmount = function (cart) {
    var sum = 0;
    if(typeof cart.data.grandtotal != 'undefined') {
        var poin = 0;
        sum = cart.data.grandtotal;
        poin = cart.data.point_will_receive;
        if($("#poin_number").length > 0) {
            $("#poin_number").html(formatMoney(poin));
        }
    } else {
        var stringified = JSON.stringify(cart.data);
        var cart = JSON.parse(stringified);
        Object.entries(cart).forEach(([key, product]) => {
            sum += parseFloat(product.amount);
        });
    }
    $("#modal_total_amount").empty();
    if(sum > 0) {
        $("#modal_total_amount").append("IDR "+formatMoney(sum));

    }

}

const apiCall = () => new Promise(res => setTimeout(res, 800,'' ));
let lastReject;
window.updateCart = function (params) {
  if (lastReject) lastReject();
  Promise.race([
    apiCall(),
    new Promise((_, rej) => {
      lastReject = rej;
    })
  ])
  .then((resp) => {
    var updateCart = $.ajax({
        type:'POST',
        url:"/update-qty-cart",
        data:params,
        beforeSend:function() {
            if($('.nominal-grand-total').length > 0) {
                $('.nominal-grand-total').addClass('loading-ajax');
                $('#modal_total_amount').addClass('blured-item');
            }
            if($('.total-amount').length > 0) {
                $('.total-amount').addClass('loading-ajax');
                $('.total-amount span').addClass('blured-item');
            }
            if($('.total-poin').length > 0) {
                $('.total-poin').addClass('loading-ajax');
                $('.total-poin div').addClass('blured-item');
            }
        },
        success:function(data){
            if(data.status == 'success'){
                sumTotalAmount(data);
            }
        },
        complete:function(){
            if($('.nominal-grand-total').length > 0) {
                $('.nominal-grand-total').removeClass('loading-ajax');
                $('#modal_total_amount').removeClass('blured-item');
            }
            if($('.total-amount').length > 0) {
                $('.total-amount').removeClass('loading-ajax');
                $('.total-amount span').removeClass('blured-item');
            }
            if($('.total-poin').length > 0) {
                $('.total-poin').removeClass('loading-ajax');
                $('.total-poin div').removeClass('blured-item');
            }
        },
        error:function(jqXHR){
            console.log(jqXHR)
        }
    });
  })
  .catch(() => {
    console.log('Quick click: previous ongoing API call will be ignored');
  });
}

// window.rateRating = function (value) {

//     const ratings = {
//         star_rating: value??Math.random() + 1
//     };
//     console(rating);
//     // total number of stars
//     const starTotal = 5;

//     for (const rating in ratings) {


//         const starPercentage = (ratings[rating] / starTotal) * 100;
//         const starPercentageRounded = `${Math.round(starPercentage / 10) *
//             10}%`;
//         document.querySelector(
//             `#${rating} .stars-inner`
//         ).style.width = starPercentageRounded;
//     }
// }

window.pagination = function(pagi, params=null) {
    let param = params != null ? '&'+params :'';
    let prevPage = pagi.current_page - 1;
    let nextPage = pagi.current_page + 1;
    let num = "";
    var prev = "";
    var next = "";
    var queryParams = new URLSearchParams(window.location.search);
    let queryLink = (new URL(location.protocol + '//' + location.host + location.pathname +'?'+queryParams.toString())).searchParams;

    
    if (pagi.prev_page_url != null) {
        queryLink.set('page', prevPage );
        let pageLink = location.protocol + '//' + location.host + location.pathname + '?' +queryLink;

        prev = '<div class="page-item prev">'+
                      '<a class="page-link" href="'+pageLink + param+'" aria-label="Previous">'+
                          '<img data-src="/img/icon/icon-arrow-left.svg" class="lazyload img-fluid" />'+
                          '<span class="sr-only">Previous</span>'+
                      '</a>'+
                  '</div>';
    } else if (pagi.last_page > 1) {
        prev = '<div class="page-item prev disabled">'+
        '<a class="page-link" href="" aria-label="Previous">'+
            '<img data-src="/img/icon/icon-arrow-left.svg" class="lazyload img-fluid" style="opacity:0.5" />'+
            '<span class="sr-only">Previous</span>'+
        '</a>'+
    '</div> ';
    }
    if (pagi.next_page_url != null) {
        queryLink.set('page', nextPage );
        let pageLink = location.protocol + '//' + location.host + location.pathname + '?' +queryLink;

        next = '<div class="page-item next">'+
                    '<a class="page-link" href="'+pageLink + param +'" aria-label="Next">'+
                        '<img data-src="/img/icon/icon-arrow-right.svg" class="lazyload img-fluid" />'+
                        '<span class="sr-only">Next</span>'+
                    '</a>'+
                '</div>';
    } else if (pagi.last_page > 1) {
        next =
            '<div class="page-item next disabled">'+
        '<a class="page-link" href="" aria-label="Next">'+
        '<img data-src="/img/icon/icon-arrow-right.svg" class="lazyload img-fluid" style="opacity:0.5" />'+
            '<span class="sr-only">Next</span>'+
        '</a>'+
   ' </div>';
    }
    if (pagi.last_page > 1) {
        const limit_num = 7;
        for (let i = 1; i <= pagi.last_page; i++) {
            let half_total_num = Math.floor(limit_num / 2);
            var from = pagi.current_page - half_total_num;
            var to = pagi.current_page + half_total_num;
            if (pagi.current_page < half_total_num) {
                to += half_total_num - pagi.current_page;
            }
            if (pagi.last_page - pagi.current_page < half_total_num) {
                from -=
                    half_total_num - (pagi.last_page - pagi.current_page) - 1;
            }
            if (from < i && i < to) {
                queryLink.set('page', i );

                let pageLink = location.protocol + '//' + location.host + location.pathname + '?' +queryLink;

                let active = pagi.current_page == i ? "active" : "";
                num +=
                    '<div class="page-item ' + active +'">'+
                        '<a class="page-link" href="' +pageLink + param + '">' + i + '</a>'+
                    '</div>';
            }
        }
    }
    var nav =
        '<nav class="pagination" aria-label="Page navigation">' + prev +
                '<div class="wrap-page-item">' + num +'</div>'+ next +
        '</nav>';

    var html = nav;
    return html;
}