
$('[data-toggle="tooltip"]').tooltip();

$('.carousel-products, .list-products, .carousel-related, .product_slider_box').on('change', '[name="wishlist"]' ,function () {
    let form = {
        'product_code'   : $(this).data('id')
    }
    if ($(this).prop('checked')) {
        addWishlist(form, this);
    } else {
        removeWishlist(form, this);
    }
});



function getWishlist() {

    if($('#list_wishlist').length) {
        var params = {
            page : $('#list_wishlist').data('page')
        }
        $.ajax({
            type: "POST",
            url:BASE_URL+"/wishlist/get_list_wishlist",
            data: params,
            beforeSend: function() {
                $(".preloader").fadeIn();
            },
            success: function(data) {
             $('#list_wishlist').html(data.html);
    
            },
            complete: function() {
                $(".preloader").fadeOut();
            },
            error: function(jqXHR) {
                console.log(jqXHR);
            }
        });
    }
}

function addWishlist(params, el) {
    $.ajax({
       type:'POST',
       url:BASE_URL+"/wishlist/add-wishlist",
       data:params,
        success:function(data){
            if(data.status == 'failed') {
                window.location.replace(BASE_URL+"/login");
            }  else {
                $(el).parent('label').attr('data-original-title', data.label);
                $('.notification-top').show();
                $('#notify-2').prop('checked', false);
                $("#notify2_message").html(data.message);
                if($('#notify-2').length  == 1) {
                    setTimeout(function () {
                        $('#notify-2').trigger('click');
                    }, 3000);
                }
                getWishlist();
            }
  
        },
        complete:function(){},
        error:function(jqXHR){
            console.log(jqXHR)
        }
    });
}

function removeWishlist(params, el) {
    $.ajax({
       type:'POST',
       url:BASE_URL+"/wishlist/remove-wishlist",
       data:params,
        success:function(data){
            $(el).parent('label').attr('data-original-title', data.label);

            $('.notification-top').show();
            $('#notify-2').prop('checked', false);
            $("#notify2_message").html(data.message);
            if($('#notify-2').length  == 1) {
                setTimeout(function () {
                    $('#notify-2').trigger('click');
                }, 3000);
            }
            getWishlist();
        },
        complete:function(){},
        error:function(jqXHR){
            console.log(jqXHR)
        }
    });
}