$("#list_modal_cart .qty, .list_shopping_cart .qty").keyup(function () {
    if ($(this).val() <= 0) {
        $('.btn_add_to_cart').prop("disabled", true);
        $(this).val(0);
    } else {
        $('.btn_add_to_cart').prop("disabled", false);
    }
    var price = $('.price_qty');
    var qty = $(this).val();
    var pcs = $('input[name="old_price_id"]').val();
    price.html(formatNumber(pcs * qty));
});

$("#list_modal_cart, .list_shopping_cart").on("click", ".minus, .plus", function () {
    var p = update_qty(this);
    var data = {
        product_code: $(this).closest('.item')[0].id,
        quantity: p.qty,
        amount: (p.pcs * p.qty)
    }
    updateCart(data);
});
// $("#list_modal_cart, .list_shopping_cart").on("blur", ".minus, .plus", function () {
//     var p = update_qty(this);
//     var data = {
//         product_code: $(this).closest('.item')[0].id,
//         quantity: p.qty,
//         amount: (p.pcs * p.qty)
//     }
//     updateCart(data);
// });
$(".product-quantity").on("click", ".minus, .plus", function () {
    update_qty(this);
});
$("#list_modal_cart, .list_shopping_cart").on("change", ".qty", function () {
    var p = update_qty(this);
    var data = {
        product_code: $(this).closest('.item')[0].id,
        quantity: p.qty,
        amount: (p.pcs * p.qty)
    }
    updateCart(data);
});
$(".product-quantity").on("change", ".qty", function () {
    update_qty(this);
});
var update_qty = function (x) {
    var $button = $(x);
    var oldValue = $button.parent().find(".qty").val();
    // var pricePage = $button.parentsUntil('.page').find('.price_qty');
    var priceModal = $button.parentsUntil('.item').find('.price_qty');
    var pcs = $button.parentsUntil('.item').find('input[name="old_price_id"]').val();
    var deleteItem = $button.parentsUntil('.item').find('.btn-delete');
    var amount = $('#amount');
    var newVal;

    if ($button.val() == '+') {
        newVal = parseFloat(oldValue) + 1;
        amount.val(pcs * newVal);
        $('.btn_add_to_cart').prop("disabled", false);
        priceModal.html(formatNumber(pcs * newVal));
        priceModal.data('id', pcs * newVal);
    } else {
        if ($button.val() == '-') {
            if (oldValue < 2) {
                newVal = 1;
                deleteItem.trigger('click');
            } else {
                newVal = parseFloat(oldValue) - 1;
            }
        } else {
            if (oldValue < 1) {
                newVal = 1;
                deleteItem.trigger('click');
            } else {
                newVal = parseFloat(oldValue);
            }
        }
        amount.val(pcs * newVal);
        priceModal.html(formatNumber(pcs * newVal));
        priceModal.data('id', pcs * newVal);
    }
    
    $button.parent().find(".qty").val(newVal);
    var data = {
        pcs: pcs,
        qty: newVal
    }
    return data;
}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}