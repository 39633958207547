if($('#notify-1').length > 0) {
    setTimeout(function () {
        $('#notify-1').trigger('click');
    }, 3000);
}

$( function () {
  window.onscroll = function() {getNewPosition()};
  
  function getNewPosition() {
    if (window.pageYOffset != 0) {
      $(".notification-bar").css("top", "90px");
  } else {
      $(".notification-bar").css("top", "90px");
    }
  }
});