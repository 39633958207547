require('./bootstrap');
require('./bootstrap-validate');
require('select2');
// require('./vendor/magnify/jquery.magnify');
// require('./vendor/magnify/jquery.magnify-mobile');
window.StarRating = require('star-rating.js');
window.moment = require('moment');
window.Swal = require('sweetalert2')
require('./vendor/lazysizes.min');

require('./owl.carousel.min');
require('./helper.js');
require('./front/magnify');
require('./front/navbar');
require('./front/slider');
require('./front/filter');
require('./front/wishlist');
require('./front/product');
require('./front/add_to_cart');
require('./front/btn_quantity');

require('./front/return_to_top');
require('./front/select2city');
require('./front/form_validate');

require('./front/custom');

require('./front/search/search_result');
require('./front/login');
require('./front/newsletters');
require('./front/order');