$(function () {
    if($('#province').length > 0) {
        $('#province').select2({
            // placeholder: "Select province",
            // allowClear: true
        });
        $('#city').select2({
            // placeholder: "Select a city",
            // allowClear: true,
            selectOnClose: false

        });
        $('#zip_code').select2({
            // placeholder: "Select a zipcode",
            // allowClear: true,
            selectOnClose: false
        });
        $('#province').on('change', function () {
            $('#city').empty();
            $('#zip_code').empty();
            $('#address').empty();
            $("#address").text('');
            $("#address").val('');
            $("#zip_code").prop("disabled", true);
            $("#city").prop("disabled", true);
            if($("#province").val() != '') {
                $.post( BASE_URL+"/my-account/cities-ajax/"+$("#province").val(), function( data ) {
                    $("#city").prop("disabled", false);
                    $('#city').append('<option value="">Select a city</option>');
                    $.each( data, function( key, value ) {
                        $('#city').append('<option value="'+key+'">'+value+'</option>');
                    });
                    $("#zip_code").prop("disabled", true);

                    if($('#old_city').val() != '') {
                        var $city = $("#city").select2();
                        $city.val($('#old_city').val()).trigger("change");
                    }
                });
            }
        });
        $('#city').on('change', function () {
            $('#address').empty();
            $("#address").text('');
            $("#address").val('');
            $('#zip_code').empty();
            $("#zip_code").prop("disabled", true);
            if($("#city").val() != '') {
                $.post( BASE_URL+"/my-account/zipcode-ajax/"+$("#city").val(), function( data ) {
                    $('#zip_code').empty();
                    $('#zip_code').append('<option value="">Select a zipcode</option>');
                    $.each( data, function( key, value ) {
                        $('#zip_code').append('<option value="'+key+'">'+value+'</option>');
                    });
                    $("#zip_code").prop("disabled", false);
                    if($('#old_zip_code').val() != '') {
                        var $zip_code = $("#zip_code").select2();
                        $zip_code.val($('#old_zip_code').val()).trigger("change");
                        if($('#old_zip_code').val() == $('#zip_code').val()) {
                            var $address = $("#address");
                            if($address.length > 0) {
                                $address.val($('#old_address').val());
                            }
                        }
                    }
                });
            }
        });
    }
});
