if (document.getElementsByClassName('owl-slider').length > 0) {
    homepage_slider(false);
}
// if (document.getElementById('product_slider')) {
//     slider_single_product();
// }
if (document.getElementsByClassName('carousel-products').length > 0) {
    carousel();
}
if (document.getElementsByClassName('carousel-inspirations').length > 0) {
    inspirations();
}
if (document.getElementsByClassName('slide-icon-text').length > 0) {
    slide_icon_text();
}
if (document.getElementsByClassName('carousel-awards').length > 0) {
    carousel_awards();
}
if (document.getElementsByClassName('package-membership-tier').length > 0) {
    membership();
}
if (document.getElementsByClassName('membership-priviledges').length > 0) {
    membership_priviledges();
}

window.addEventListener('resize', carousel_awards);


function homepage_slider(autoslide=false) {

    let promoCard = $('.owl-promo-card');
    let slider = $('.owl-slider');
    promoCard.owlCarousel({
        items:1,
        singleItem:true,
        loop:true,
        margin:10,
        pagination:false,
        navigation:false,
        dots:false,
        touchDrag:false,
        mouseDrag:false,
        responsive:{
            0:{
                items:1,
            },
            768:{
                items:1,
            },
        }
    });
    slider.owlCarousel({
        loop:true,
        margin:10,
        nav:false,
        responsiveClass:true,
        autoplay:true,
        autoplayHoverPause:false,
        autoplayTimeout:5000,
        pagination:false,
        navigation:true,
        touchDrag:true,
        mouseDrag:true,
        onTranslate: function (e) {
            promoCard.trigger('to.owl.carousel', e.page.index);
        },
        responsive:{
            0:{
                items:1,
            },
            768:{
                items:1,
            },
        }
    });

}
function carousel() {
    $('.our-products').owlCarousel({
        loop:false,
        nav:false,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        margin:10,
        responsiveClass:true,
        autoplay:false,
        responsive:{
            0:{
                items:3,
            },
            768:{
                nav:true,
                items:3,
            }
        }
    });
    $('.best-products').owlCarousel({
        loop:false,
        nav:true,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        dots:false,
        margin:50,
        responsiveClass:true,
        autoplay:false,
        responsive:{
            0:{
                items:1,
            },
            768:{
                items:3,
            }
        }
    });
    $('.carousel-related').owlCarousel({
        loop:false,
        nav:false,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        dots:false,
        margin:50,
        responsiveClass:true,
        autoplay:false,
        responsive:{
            0:{
                items:2,
                margin:20,
            },
            768:{
                items:3,
            }
        }
    });
}
function inspirations() {
    $('.carousel-inspirations').owlCarousel({
        loop:true,
        autoplay:true,
        nav:true,
        dots:false,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        margin:0,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
            },
            600:{
                items:1,
            },
        }
    })
}

// function slider_single_product() {
//     var sync2 = $('#product_slider'),
//     sync1 = $('#product_view_slider'),
//     duration = 300,
//     thumbs = 4;

    
//     // Sync nav
//     sync1.on('click', '.owl-next', function () {
//         sync2.trigger('next.owl.carousel')
//     });
//     sync1.on('click', '.owl-prev', function () {
//         sync2.trigger('prev.owl.carousel')
//     });

//     // Start Carousel
//     sync1.owlCarousel({
//         center : true,
//         loop : true,
//         items : 1,
//         margin:0,
//         nav : false,
//         dots : false
//     }).on('dragged.owl.carousel', function (e) {
//         if (e.relatedTarget.state.direction == 'left') {
//             sync2.trigger('next.owl.carousel')
//         } else {
//             sync2.trigger('prev.owl.carousel')
//         }
//     });

//     sync2.owlCarousel({
//         center : true,
//         loop : true,
//         items : thumbs,
//         items : 2,
//         margin:10,
//         nav : false,
//         animateOut: 'slideOutUp',
//         animateIn: 'slideInUp'
//     }).on('click', '.owl-item', function() {
//         var i = $(this).index()-(thumbs+1);
//         sync2.trigger('to.owl.carousel', [i, duration, true]);
//         sync1.trigger('to.owl.carousel', [i, duration, true]);
//     });
// }

function slide_icon_text() {
    $('.slide-icon-text').owlCarousel({
        loop:false,
        nav:true,
        dots:false,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        autoplay:false,
        margin:0,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
            },
            600:{
                items:2,
            },
        }
    })
}
function carousel_awards() {
    if(window.innerWidth > 768) {
        $('.carousel-awards').owlCarousel({
            loop:false,
            nav:false,
            dots:false,
            navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
            autoplay:false,
            margin:0,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                },
                600:{
                    margin:25,
                    items:6,
                    nav:true
                },
            }
        })
    } else {
        $('.carousel-awards').owlCarousel('destroy');
    }
}

function membership() {
    $('.package-membership-tier').owlCarousel({
        loop:false,
        nav:false,
        dots:false,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        autoplay:false,
        margin:0,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            768:{
                margin:25,
                items:3,
            },
        }
    })
}

function membership_priviledges() {
    $('.membership-priviledges.third').owlCarousel({
        loop:false,
        nav:false,
        dots:false,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        autoplay:false,
        margin:50,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            768:{
                margin:100,
                items:3,
            },
        }
    })
    $('.membership-priviledges.fourth').owlCarousel({
        loop:false,
        nav:false,
        dots:false,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        autoplay:false,
        margin:50,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            768:{
                margin:0,
                items:4,
            },
        }
    })
}